import React from 'react'
import Slider from '@fil1pe/react-slider'
import * as st from '~/assets/styl/Feedbacks.module.styl'
import cn from 'classnames'

type Props = {
  marginNegative?: boolean
  data: Array<any>
}

const Feedbacks = ({ marginNegative, data }: Props) => {
  if (data.length > 0) {
    return (
      <section className={cn(st.core, marginNegative && st.marginNegative)}>
        <h2>Depoimentos</h2>
        <p>Quem já conta com a tranquilidade Dimas Seguros</p>
        <div className="container">
          <Slider
            slidesToShow={3}
            slidesToScroll={1}
            className="slider feedbacks"
          >
            {data.map(({ image, name, office, description }, index) => (
              <div key={index} className="slide">
                <p>{description}</p>
                <div>
                  <div>
                    <img src={image} alt={`Foto de perfil de ${name}`} />
                  </div>
                  <div>
                    <p>{name}</p>
                    <p>{office}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <ul className={st.mobile}>
            {data.map(({ image, name, office, description }, index) => (
              <li key={index}>
                <p>{description}</p>
                <div>
                  <div>
                    <img src={image} alt={`Foto de perfil de ${name}`} />
                  </div>
                  <div>
                    <p>{name}</p>
                    <p>{office}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  } else {
    return <></>
  }
}

export default Feedbacks
