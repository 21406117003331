import React from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/LinkInsurances.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

type Props = {
  location: string
  data?: any
}

const LinkInsurances = ({ data, location }: Props) => {
  return (
    <div className={st.core}>
      <p>
        {location === '/'
          ? 'Garanta uma vida mais tranquila.'
          : data.ensureTitle}
      </p>
      {location === '/' ? (
        <Link to="/seguros/">Conheça nossos seguros</Link>
      ) : (
        <AnchorLink to={`/${location}/#fale-conosco`}>
          {data.ensureCta}
        </AnchorLink>
      )}
    </div>
  )
}

export default LinkInsurances
