import React, { useEffect, useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useApi } from '~/api'
import logo from '~/assets/svg/dimas-seguros.png'
import insta from '~/assets/svg/svg-insta.svg'
import * as st from '~/assets/styl/Menu.module.styl'
import cn from 'classnames'

type Props = {
  location: Location
}

const Menu = ({ location }: Props) => {
  const [transparent, setTransparent] = useState(true)

  const query = useStaticQuery(graphql`
    query {
      allSocials {
        nodes {
          instagram
        }
      }
      allSafes {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const socialsData = useApi(
    { social: query.allSocials.nodes, safes: query.allSafes.nodes },
    'configuration'
  )

  useEffect(() => {
    if (location.pathname === '/') {
      const onScroll = () => {
        setTransparent(window.scrollY <= 700)
      }
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  })

  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
    document.body.classList.add('hidden')
  }
  const handleClose = () => {
    setNavbarOpen(false)
    document.body.classList.remove('hidden')
  }

  return (
    <header
      className={cn(
        st.core,
        transparent && location.pathname === '/' && st.transparent,
        !transparent && st.animate
      )}
    >
      <div className="container clear-fix">
        {!navbarOpen && (
          <button onClick={handleToggle}>
            <div></div>
            <div></div>
            <div></div>
          </button>
        )}
        <Link to="/">
          <img
            src={logo}
            alt="Logo da D e D Corretora de Seguros"
            loading="lazy"
          />
        </Link>
        <nav
          className={cn(
            `${st.navMobile} ${navbarOpen ? `${st.showMenu}` : ''}`
          )}
        >
          {navbarOpen && <button onClick={handleClose}>Fechar</button>}
          <ul>
            <li>
              <Link to="/" onClick={handleClose}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/seguros/" onClick={handleClose}>
                Seguros
              </Link>
              <ul>
                {socialsData.safes.map(({ slug, name }, index) => (
                  <li key={index}>
                    <Link to={`/${slug}/`} onClick={handleClose}>
                      {!navbarOpen ? name : name.replace('Seguro ', '')}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link to="/sobre/" onClick={handleClose}>
                Institucional
              </Link>
            </li>
            <li>
              <Link to="/contato/" onClick={handleClose}>
                Contato{' '}
              </Link>
            </li>
            <li>
              <Link to="/seguro-automovel/#simulacao" onClick={handleClose}>
                Contrate agora{' '}
              </Link>
            </li>
          </ul>
        </nav>
        <a href={socialsData.social.instagram} target="_blank" rel="noreferrer">
          <img src={insta} alt="Instagram D e D Corretora de Seguros" />
        </a>
      </div>
    </header>
  )
}

export default Menu
